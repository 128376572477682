var render = function render(){
  var _vm$summary;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v(" 댓글 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : _vm$summary.totalCount))])])]), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('comments', {
    attrs: {
      "value": _vm.items,
      "replyable": ""
    },
    on: {
      "save": _vm.save,
      "remove": _vm.remove
    }
  }), _c('div', {
    staticClass: "pt-3"
  }, [_c('comment-input', _vm._b({
    attrs: {
      "relative": ""
    },
    on: {
      "save": _vm.save
    }
  }, 'comment-input', {
    isLoggedIn: _vm.isLoggedIn
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }