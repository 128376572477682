<template>
    <li :class="{ 'light-blue lighten-5': isRouted }">
        <v-card ref="comment" color="transparent" tile :disabled="value?.isDeleted">
            <v-card-subtitle>
                <v-row class="mx-n1 my-n2" align="center">
                    <v-col cols="auto" class="pa-1 font-weight-bold">{{ name }} </v-col>
                    <v-col cols="auto" class="pa-1">{{ value?.createdAt?.toDateTime?.() }}</v-col>
                    <v-spacer />
                    <template v-if="isLoggedIn && replyable">
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="showsReply = !showsReply" x-small text tile color="secondary" class="pa-1">
                                <span> 답글달기 </span>
                            </v-btn>
                        </v-col>
                    </template>
                    <template v-if="isWriter">
                        <template v-if="replyable">
                            <v-col cols="auto" class="pa-1">·</v-col>
                        </template>
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="isEdit = !isEdit" x-small text tile color="secondary" class="pa-1">
                                <span> 수정 </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pa-1">·</v-col>
                        <v-col cols="auto" class="pa-1">
                            <v-btn @click="$emit('remove', value)" x-small text tile color="secondary" class="pa-1">
                                <span> 삭제 </span>
                            </v-btn>
                        </v-col>
                    </template>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <comment-input
                    v-if="isEdit"
                    v-model="value"
                    v-bind="{
                        isLoggedIn,
                        privatable,
                    }"
                    usesCustomActivator
                    buttonText="수정"
                    @save="(item) => ($emit('save', item) || true) && (isEdit = !isEdit)"
                />
                <template v-else-if="value.isDeleted"> 블라인드된 댓글입니다. </template>
                <template v-else>
                    <v-row class="mx-n1 my-n1" align="center">
                        <v-col cols="auto" class="pa-1">
                            <template v-if="value.isPrivate">
                                <template v-if="isUser || isWriter">
                                    <v-icon small> mdi-lock-outline </v-icon> <b v-if="value?.mention" class="primary--text mr-1">{{ value.mention }}</b>
                                    {{ value?.content }}
                                </template>
                                <template v-else>
                                    <v-icon small> mdi-lock-outline </v-icon>
                                    비밀글 입니다.
                                </template>
                            </template>
                            <template v-else>
                                <b v-if="value?.mention" class="primary--text mr-1">{{ value.mention }}</b>
                                {{ value?.content }}
                            </template>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="auto" class="pa-1">
                            <template>
                                <like-btn :params="{ ...likeParams }" @input="setLikeCount">
                                    <template #activator="{ active, toggle }">
                                        <v-btn icon rounded outlined color="transparent" class="cursor-pointer" @click="toggle">
                                            <span v-if="active" class="material-icons-outlined accent2--text">thumb_up</span>
                                            <span v-else class="material-icons-outlined primary--text">thumb_up</span>
                                        </v-btn>
                                    </template>
                                </like-btn>
                                <span class="font-weight-medium txt txt--sm">{{ likeCount?.format?.() }}</span>
                            </template>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <template v-if="replyable && (!value.isPrivate || isUser || isWriter)">
                <v-card-actions class="pt-0 pa-4">
                    <v-card outlined tile @click="showsChildren = !showsChildren">
                        <v-btn x-small text>
                            <v-row dense class="ma-0" align="baseline">
                                <v-col cols="auto">
                                    <v-icon>mdi-message-processing-outline</v-icon>
                                </v-col>
                                <v-col cols="auto"> 답글 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-bold"> {{ value?.children?.length }} </span>
                                </v-col>
                            </v-row>
                        </v-btn>
                    </v-card>
                </v-card-actions>
            </template>
        </v-card>
        <v-divider />
        <v-expand-transition>
            <ul v-show="showsChildren" v-if="0 < value?.children?.length">
                <template v-for="item in value?.children || []">
                    <comment-reply :key="item._id" v-bind="{ value: item }" @mention="onMention" @save="(item) => $emit('save', item)" @remove="(item) => $emit('remove', item)" />
                </template>
            </ul>
        </v-expand-transition>

        <v-expand-transition>
            <div v-if="showsReply">
                <div class="pa-3">
                    <comment-input v-if="isLoggedIn" v-bind="{ isLoggedIn }" ref="comment-input" relative @save="(item) => $emit('save', { ...item, _comment: value._id, callback: () => (showsReply = !showsReply) })" />
                </div>
                <v-divider />
            </div>
        </v-expand-transition>
    </li>
</template>

<script>
import LikeBtn from "@/components/client/like/like-btn.vue";
import CommentInput from "./comment-input.vue";
import CommentReply from "./comment-reply.vue";
export default {
    components: {
        CommentInput,
        CommentReply,
        LikeBtn
    },
    props: {
        value: {
            user: { type: Object },
            content: { type: String, default: "" },
            children: { type: Array, default: () => [] },
        },
        expanded: { type: Boolean, default: false },
        replyable: { type: Boolean, default: false },
        privatable: { type: Boolean, default: false },
    },
    data: (props) => ({
        isEdit: false,
        showsReply: props.expanded,
        showsChildren: props.expanded,
        
        likeCount: 0
    }),
    computed: {
        name() {
            let { nickname } = this.value.writer || {};
            return nickname;
        },
        isUser() {
            return this.value?._user == this.$store?.state?.payload?._user;
        },
        isWriter() {
            return this.value?.writer?._id == this.$store?.state?.payload?._user;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        isRouted() {
            return this.$route.params._comment == this.value?._id;
        },
        likeParams() {
            return this.value?._board ? { _boardComment: this.value?._id } : { _userComment: this.value?._id };
        }
    },
    mounted() {
        if (this.isRouted) this.scrollToThis();
        this.syncLikeCount();
    },
    watch: {
        isRouted(value) {
            if (value) this.scrollToThis();
        },
    },
    methods: {
        onMention(comment) {
            if (this.showsReply && this.$refs["comment-input"]?.mention_comment == comment) {
                this.showsReply = false;
                return;
            }
            this.showsReply = true;
            this.$nextTick(() => this.$refs["comment-input"]?.onMention?.(comment));
        },
        scrollToThis() {
            this.$nextTick(() => setTimeout(() => window.scrollTo({ top: this.$refs["comment"]?.getBoundingClientRect?.()?.top - 96, behavior: "smooth" }), 500));
        },
        syncLikeCount(){
            this.likeCount = this.value?.likeCount || 0;
        },
        setLikeCount(count){
            this.likeCount = this.likeCount + count;
        }
    },
};
</script>
