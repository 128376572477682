<template>
    <ul>
        <template v-for="item in value">
            <comment
                :key="item._id"
                v-bind="{
                    value: item,
                    expanded,
                    replyable,
                    privatable,
                }"
                v-on="{
                    save: (item) => $emit('save', item),
                    remove: (item) => $emit('remove', item),
                }"
            />
        </template>
    </ul>
</template>
<script>
import Comment from "./comment.vue";
export default {
    components: { Comment },
    props: {
        value: { type: Array, default: () => [] },
        expanded: { type: Boolean, default: false },
        replyable: { type: Boolean, default: false },
        privatable: { type: Boolean, default: false },
    },
};
</script>

<style scoped></style>
