var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_vm._l(_vm.value, function (item) {
    return [_c('comment', _vm._g(_vm._b({
      key: item._id
    }, 'comment', {
      value: item,
      expanded: _vm.expanded,
      replyable: _vm.replyable,
      privatable: _vm.privatable
    }, false), {
      save: function (item) {
        return _vm.$emit('save', item);
      },
      remove: function (item) {
        return _vm.$emit('remove', item);
      }
    }))];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }