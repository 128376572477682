export const checkForBannedKeywords = (contents = "", bannedKeywords)=> {
    if(!bannedKeywords) return true;
    const keywords = filteringByKeywords(contents, bannedKeywords);
    if(keywords) throw new Error(`금지어(${keywords.join(", ")})가 포함되어 있습니다.`);
    return true;
};

export const filteringByKeywords = (contents = "", bannedKeywords)=> {
    const keywordsArray = bannedKeywords?.split(",")?.map((keyword)=> keyword?.trim());
    const regex = new RegExp(keywordsArray?.join("|"), "g");

    // return contents?.replace(regex, "***");
    return contents?.match(regex);
};