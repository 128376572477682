var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$createdAt, _vm$value2$createdAt$, _vm$value3, _vm$value4, _vm$value5, _vm$value6, _vm$likeCount, _vm$likeCount$format, _vm$value7, _vm$value7$children, _vm$value8, _vm$value8$children, _vm$value9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: {
      'light-blue lighten-5': _vm.isRouted
    }
  }, [_c('v-card', {
    ref: "comment",
    attrs: {
      "color": "transparent",
      "tile": "",
      "disabled": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.isDeleted
    }
  }, [_c('v-card-subtitle', [_c('v-row', {
    staticClass: "mx-n1 my-n2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1 font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.name) + " ")]), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$createdAt = _vm$value2.createdAt) === null || _vm$value2$createdAt === void 0 ? void 0 : (_vm$value2$createdAt$ = _vm$value2$createdAt.toDateTime) === null || _vm$value2$createdAt$ === void 0 ? void 0 : _vm$value2$createdAt$.call(_vm$value2$createdAt)))]), _c('v-spacer'), _vm.isLoggedIn && _vm.replyable ? [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-1",
    attrs: {
      "x-small": "",
      "text": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.showsReply = !_vm.showsReply;
      }
    }
  }, [_c('span', [_vm._v(" 답글달기 ")])])], 1)] : _vm._e(), _vm.isWriter ? [_vm.replyable ? [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")])] : _vm._e(), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-1",
    attrs: {
      "x-small": "",
      "text": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_c('span', [_vm._v(" 수정 ")])])], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-1",
    attrs: {
      "x-small": "",
      "text": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove', _vm.value);
      }
    }
  }, [_c('span', [_vm._v(" 삭제 ")])])], 1)] : _vm._e()], 2)], 1), _c('v-card-text', [_vm.isEdit ? _c('comment-input', _vm._b({
    attrs: {
      "usesCustomActivator": "",
      "buttonText": "수정"
    },
    on: {
      "save": function (item) {
        return (_vm.$emit('save', item) || true) && (_vm.isEdit = !_vm.isEdit);
      }
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'comment-input', {
    isLoggedIn: _vm.isLoggedIn,
    privatable: _vm.privatable
  }, false)) : _vm.value.isDeleted ? [_vm._v(" 블라인드된 댓글입니다. ")] : [_c('v-row', {
    staticClass: "mx-n1 my-n1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.value.isPrivate ? [_vm.isUser || _vm.isWriter ? [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-lock-outline ")]), _vm._v(" "), (_vm$value3 = _vm.value) !== null && _vm$value3 !== void 0 && _vm$value3.mention ? _c('b', {
    staticClass: "primary--text mr-1"
  }, [_vm._v(_vm._s(_vm.value.mention))]) : _vm._e(), _vm._v(" " + _vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.content) + " ")] : [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-lock-outline ")]), _vm._v(" 비밀글 입니다. ")]] : [(_vm$value5 = _vm.value) !== null && _vm$value5 !== void 0 && _vm$value5.mention ? _c('b', {
    staticClass: "primary--text mr-1"
  }, [_vm._v(_vm._s(_vm.value.mention))]) : _vm._e(), _vm._v(" " + _vm._s((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : _vm$value6.content) + " ")]], 2), _c('v-spacer'), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [[_c('like-btn', {
    attrs: {
      "params": Object.assign({}, _vm.likeParams)
    },
    on: {
      "input": _vm.setLikeCount
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var active = _ref.active,
          toggle = _ref.toggle;
        return [_c('v-btn', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "",
            "rounded": "",
            "outlined": "",
            "color": "transparent"
          },
          on: {
            "click": toggle
          }
        }, [active ? _c('span', {
          staticClass: "material-icons-outlined accent2--text"
        }, [_vm._v("thumb_up")]) : _c('span', {
          staticClass: "material-icons-outlined primary--text"
        }, [_vm._v("thumb_up")])])];
      }
    }])
  }), _c('span', {
    staticClass: "font-weight-medium txt txt--sm"
  }, [_vm._v(_vm._s((_vm$likeCount = _vm.likeCount) === null || _vm$likeCount === void 0 ? void 0 : (_vm$likeCount$format = _vm$likeCount.format) === null || _vm$likeCount$format === void 0 ? void 0 : _vm$likeCount$format.call(_vm$likeCount)))])]], 2)], 1)]], 2), _vm.replyable && (!_vm.value.isPrivate || _vm.isUser || _vm.isWriter) ? [_c('v-card-actions', {
    staticClass: "pt-0 pa-4"
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsChildren = !_vm.showsChildren;
      }
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-message-processing-outline")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 답글 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s((_vm$value7 = _vm.value) === null || _vm$value7 === void 0 ? void 0 : (_vm$value7$children = _vm$value7.children) === null || _vm$value7$children === void 0 ? void 0 : _vm$value7$children.length) + " ")])])], 1)], 1)], 1)], 1)] : _vm._e()], 2), _c('v-divider'), _c('v-expand-transition', [0 < ((_vm$value8 = _vm.value) === null || _vm$value8 === void 0 ? void 0 : (_vm$value8$children = _vm$value8.children) === null || _vm$value8$children === void 0 ? void 0 : _vm$value8$children.length) ? _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsChildren,
      expression: "showsChildren"
    }]
  }, [_vm._l(((_vm$value9 = _vm.value) === null || _vm$value9 === void 0 ? void 0 : _vm$value9.children) || [], function (item) {
    return [_c('comment-reply', _vm._b({
      key: item._id,
      on: {
        "mention": _vm.onMention,
        "save": function (item) {
          return _vm.$emit('save', item);
        },
        "remove": function (item) {
          return _vm.$emit('remove', item);
        }
      }
    }, 'comment-reply', {
      value: item
    }, false))];
  })], 2) : _vm._e()]), _c('v-expand-transition', [_vm.showsReply ? _c('div', [_c('div', {
    staticClass: "pa-3"
  }, [_vm.isLoggedIn ? _c('comment-input', _vm._b({
    ref: "comment-input",
    attrs: {
      "relative": ""
    },
    on: {
      "save": function (item) {
        return _vm.$emit('save', Object.assign({}, item, {
          _comment: _vm.value._id,
          callback: function () {
            return _vm.showsReply = !_vm.showsReply;
          }
        }));
      }
    }
  }, 'comment-input', {
    isLoggedIn: _vm.isLoggedIn
  }, false)) : _vm._e()], 1), _c('v-divider')], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }